<template>
  <section>
    <b-card class="card-app-design assigned-staff-card">
      <h4>Student Registration Link</h4>
      <b-button
        size="sm"
        variant="outline-primary"
        class="btn-tour-skip mr-1"
        @click="openLink(data.student_registration_link)"
      >
        <span class="mr-25 align-middle">Click Here</span>
      </b-button>
    </b-card>

    <div>
      <b-row class="mb-3">
        <b-col style="height: 440px" cols="12">
          <BarChart ref="barChart" />
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col style="height: 440px" cols="6">
          <b-card title="Earnings" class="b_card_earning" style="height: 100%">
            <div class="selected_filter" v-if="activeSlideIndex === 0">
              <b>{{ selectedFilter }}</b>
            </div>

            <div class="b_header_earning" v-if="activeSlideIndex === 0">
              <div class="d-flex align-items-center justify-content-between">
                <div></div>
                <div class="filters mr-2">
                  <span
                    v-for="filter in filters"
                    :key="filter"
                    :class="{ active: selectedFilterName === filter }"
                    class="filter_text"
                    @click="selectFilter(filter)"
                  >
                    {{ filter }}
                  </span>
                </div>
              </div>
              <div class="d-flex flex-column align-items-center">
                <date-range-picker
                  @update="handleDateUpdate"
                  :locale-data="locale"
                  :opens="opens"
                  :ranges="ranges"
                  :autoApply="autoApply"
                  v-model="dateRange"
                  class="date-range-picker"
                >
                </date-range-picker>
              </div>
            </div>

            <swiper
              :modules="modules"
              :slides-per-view="1"
              :space-between="70"
              :pagination="{
                el: '.swiper-pagination',
                clickable: true,
              }"
              :navigation="{
                nextEl: '.custom-next',
                prevEl: '.custom-prev',
              }"
              v-if="user_type"
              ref="swiper"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
            >
              <swiper-slide class="">
                <EarningChart
                  :series="series"
                  :totalEarnings="totalEarnings"
                  :isChartDataReady="isChartDataReady"
                  @goToOtherIncome="goToOtherIncome"
                />
              </swiper-slide>
              <swiper-slide class="">
                <ServiceBifuChart :date="date" @goToService="goToService" />
              </swiper-slide>
              <swiper-slide :callApi:="package_bifurcation" class="">
                <PackagesChart :service_id="service_id" />
              </swiper-slide>
            </swiper>
            <div class="swiper-pagination"></div>
          </b-card>
        </b-col>
        <b-col style="height: 440px" cols="6">
          <b-card title="Expenses" class="h-100">
            <div class="selected_filter">
              <b>{{ me_selectedFilter }}</b>
            </div>

            <div class="b_header_earning">
              <div class="d-flex align-items-center justify-content-between">
                <div></div>
                <div class="filters mr-2">
                  <span
                    v-for="filter in me_filters"
                    :key="filter"
                    :class="{ active: me_selectedFilterName === filter }"
                    class="filter_text"
                    @click="me_selectFilter(filter)"
                  >
                    {{ filter }}
                  </span>
                </div>
              </div>
            </div>
            <b-tabs nav-class="">
              <b-tab active>
                <template #title class="p-0">
                  <h5 class="font-small-4">Expenses</h5>
                </template>
                <b-row class="mt-2">
                  <b-col
                    cols="12"
                    v-for="expense in monthlyExpenses"
                    :key="expense.id"
                  >
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        mb-2
                      "
                    >
                      <div class="d-flex align-items-center">
                        <feather-icon :icon="expense.icon" size="25" />
                        <h3 class="font-medium-3 ml-1" style="color: #333">
                          {{
                            expense.type
                              .replace(/_/g, " ")
                              .replace(/\w\S*/g, function (txt) {
                                return (
                                  txt.charAt(0).toUpperCase() +
                                  txt.substr(1).toLowerCase()
                                );
                              })
                          }}
                        </h3>
                      </div>
                      <h3 class="font-medium-2" style="">
                        Rs. {{ expense.amount }}
                      </h3>
                    </div>
                  </b-col>

                  <b-col cols="12">
                    <hr class="w-100" />
                  </b-col>
                  <b-col cols="12">
                    <div
                      class="
                        d-flex
                        align-items-center
                        justify-content-between
                        mb-1
                      "
                    >
                      <h3 class="font-medium-4" style="color: #333">Total</h3>
                      <h3 class="font-medium-2">
                        Rs. {{ totalMonthlyExpenses }}
                      </h3>
                    </div>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab>
                <template #title class="p-0">
                  <h5 class="font-small-4">Add Expense</h5>
                </template>
                <MonthlyExpenses
                  :month="me_month"
                  :year="me_year"
                  @refreshBarChart="refreshBarChart"
                  :user_type="user_type"
                />
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
      <div>
        <StatisticCard v-if="user_type" :user_type="user_type" />
        <div v-if="!user_type">Loading Statistics .....</div>
      </div>
    </div>

    <staff-home />

    <div class="row">
      <div class="col-lg-12"></div>

      <div class="col-lg-12">
        <OtherDetails :data="data" />
      </div>
    </div>
    <b-row> </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardTitle,
  BCardHeader,
  BForm,
  BFormGroup,
  BFormInput,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
import { Navigation, Pagination } from "swiper";

SwiperCore.use([Navigation, Pagination]);
import moment from "moment";

import { FILESURL } from "@/config";
import AgentServices from "@/apiServices/AgentServices";
import store from "@/store";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import StatisticCard from "./Resources/StatisticCard.vue";
import EarningChart from "./Resources/EarningChart.vue";
import ServiceBifuChart from "./Resources/ServiceBifuChart.vue";
import StaffHome from "../StaffHome/StaffHome.vue";
import OtherDetails from "./helper/OtherDetails.vue";
import BarChart from "./Resources/BarChart.vue";
import CommonServices from "@/apiServices/CommonServices";
import PackagesChart from "./Resources/FranchiseTrainingVideos/PackagesChart.vue";
import MonthlyExpenses from "../Settings/MonthlyExpenses.vue";
import CounselorServices from "@/apiServices/CounselorServices";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardTitle,
    BCardHeader,
    StatisticCard,
    EarningChart,
    Swiper,
    SwiperSlide,
    ServiceBifuChart,
    DateRangePicker,
    StaffHome,
    OtherDetails,
    BarChart,
    PackagesChart,
    BForm,
    BFormGroup,
    BFormInput,
    BTabs,
    BTab,
    MonthlyExpenses,
  },
  data() {
    const startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    const endDate = new Date();
    return {
      month: moment().format("MMMM"),
      year: new Date().getFullYear(),
      service_id: null,
      swiperInstance: null,
      modules: [Navigation, Pagination],
      FILESURL,
      data: {},
      series: [0, 0],
      totalEarnings: 0,
      isChartDataReady: false,
      isBifurChartDataReady: false,
      isEarningChartLoaded: false,
      package_bifurcation: false,
      // spaceBetween: 60,
      maxDate: new Date(),
      locale: { firstDay: 1, format: " " },
      ranges: {
        // Today: [new Date(), new Date()],
        "This Week": [
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay())
          ),
          new Date(),
        ],
        "Last Week": [
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay() - 7)
          ),
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay() - 1)
          ),
        ],
        "This Month": [
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          new Date(),
        ],
        "This Year": [
          new Date(new Date().getFullYear(), 0, 1),
          new Date(new Date().getFullYear(), 11, 31),
        ],
        "This Quarter": [
          new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3,
            1
          ),
          new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3 + 3,
            0
          ),
        ],
        Lifetime: [null, null],
      },
      quarter: null,
      opens: "left",
      autoApply: true,
      date: `${moment(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      ).format("YYYY-MM-DD")} to ${moment(new Date()).format("YYYY-MM-DD")}`,
      dateRange: {
        startDate: moment(startDate).toDate(),
        endDate: moment(endDate).toDate(),
      },
      selectedFilterName: "This Month",
      selectedFilter: moment().format("MMMM"),
      filters: ["This Month", "This Quarter", "This Year", "Lifetime"],
      monthlyExpenses: [],
      totalMonthlyExpenses: 0,
      me_selectedFilterName: "This Month",
      me_selectedFilter: moment().format("MMMM"),
      me_filters: ["This Month", "This Quarter", "This Year", "Lifetime"],
      me_month: moment().format("MMMM"),
      me_year: new Date().getFullYear(),
      account_created_at: null,
      activeSlideIndex: 0,
    };
  },
  methods: {
    moment,
    onSlideChange() {
      if (this.swiperInstance) {
        this.activeSlideIndex = this.swiperInstance.activeIndex;
        console.log("Current slide index:", this.activeSlideIndex);
      }
    },
    async getFranchiseStatistics() {
      try {
        this.isChartDataReady = false;

        const payload = { dateRange: this.date };
        let res;
        if (this.user_type === "franchise") {
          res = await AgentServices.getFranchiseStatistics(payload);
        }
        if (this.user_type === "counselor") {
          res = await CommonServices.getFranchiseStatistics({
            franchise_id: this.franchise_id,
            ...payload,
          });
        }

        this.franchise_statistics = res.data.data.statistics;
        this.series = [
          this.franchise_statistics.total_aliff_commission,
          this.franchise_statistics.total_service_commission,
        ];

        this.totalEarnings =
          this.franchise_statistics.total_aliff_commission +
          this.franchise_statistics.total_service_commission;

        this.account_created_at = res.data.data.created_at_date;

        this.isChartDataReady = true;
      } catch (error) {
        console.log("Error in getting getFranchiseStatistics Agent ", error);
      }
    },

    handleDateUpdate(value) {
      const selectedRange = Object.entries(this.ranges).find(
        ([name, range]) =>
          range[0]?.toDateString() === value.startDate?.toDateString() &&
          range[1]?.toDateString() === value.endDate?.toDateString()
      );
      this.selectedFilterName = selectedRange
        ? selectedRange[0]
        : "Custom Range";

      if (value.startDate === null && value.endDate === null) {
        this.dateRange = { startDate: "", endDate: "" };
        this.date = "Lifetime";
      } else {
        const formattedStartDate = moment(value.startDate).format("YYYY-MM-DD");
        const formattedEndDate = moment(value.endDate).format("YYYY-MM-DD");
        this.dateRange = {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        };
        this.date = `${formattedStartDate} to ${formattedEndDate}`;
      }

      if (this.selectedFilterName === "This Month") {
        this.selectedFilter = moment().format("MMMM");
      }

      if (this.selectedFilterName === "This Quarter") {
        this.selectedFilter = `Q${Math.floor(new Date().getMonth() / 3) + 1}`;
      }

      if (this.selectedFilterName === "This Year") {
        this.selectedFilter = new Date().getFullYear();
      }
      if (this.selectedFilterName === "Lifetime") {
        this.selectedFilter =
          new Date(this.account_created_at).toLocaleString("default", {
            year: "numeric",
            month: "short",
          }) +
          " - " +
          new Date().toLocaleString("default", {
            year: "numeric",
            month: "short",
          });
      }

      this.getFranchiseStatistics();
    },

    async getHomeData() {
      try {
        const res = await AgentServices.getHomeData();
        if (res.data.status) {
          this.data = res.data.data;
        }
      } catch (err) {
        console.log("Error in getting getHomeData Agent ", err);
      }
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    selectFilter(filter) {
      this.selectedFilterName = filter;

      let startDate, endDate;
      switch (filter) {
        case "This Month":
          startDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
          );
          endDate = new Date();
          this.dateRange = {
            startDate: moment(startDate).toDate(),
            endDate: moment(endDate).toDate(),
          };
          break;
        case "This Quarter":
          startDate = new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3,
            1
          );
          endDate = new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3 + 3,
            0
          );
          this.dateRange = {
            startDate: moment(startDate).toDate(),
            endDate: moment(endDate).toDate(),
          };
          this.quarter = `Q${Math.floor(new Date().getMonth() / 3) + 1}`;
          break;
        case "This Year":
          startDate = new Date(new Date().getFullYear(), 0, 1);
          endDate = new Date(new Date().getFullYear(), 11, 31);
          this.dateRange = {
            startDate: moment(startDate).toDate(),
            endDate: moment(endDate).toDate(),
          };
          break;
        case "Lifetime":
          startDate = null;
          endDate = null;
          break;
        default:
          return;
      }

      const dateRange = {
        startDate: startDate ? moment(startDate).toDate() : null,
        endDate: endDate ? moment(endDate).toDate() : null,
      };

      this.handleDateUpdate(dateRange);
    },
    me_selectFilter(filter) {
      this.me_selectedFilterName = filter;

      let month;
      let year = new Date().getFullYear();
      switch (filter) {
        case "This Month":
          month = new Date().toLocaleString("default", { month: "long" });
          this.me_selectedFilter = month;
          break;
        case "This Quarter":
          const currentMonth = new Date().getMonth();
          if (currentMonth >= 0 && currentMonth <= 2) {
            month = "January,February,March";
          } else if (currentMonth >= 3 && currentMonth <= 5) {
            month = "April,May,June";
          } else if (currentMonth >= 6 && currentMonth <= 8) {
            month = "July,August,September";
          } else if (currentMonth >= 9 && currentMonth <= 11) {
            month = "October,November,December";
          }
          this.me_selectedFilter = `Q${
            Math.floor(new Date().getMonth() / 3) + 1
          }`;
          break;
        case "This Year":
          month = null;
          this.me_selectedFilter = year;
          break;
        case "Lifetime":
          month = null;
          year = null;
          this.me_selectedFilter =
            new Date(this.account_created_at).toLocaleString("default", {
              year: "numeric",
              month: "short",
            }) +
            " - " +
            new Date().toLocaleString("default", {
              year: "numeric",
              month: "short",
            });
          break;
        default:
          return;
      }
      this.me_month = month;
      this.me_year = year;
    },

    onSwiper(swiper) {
      this.swiperInstance = swiper; // Save the Swiper instance when initialized
    },
    goToService(service_id) {
      this.service_id = service_id;
      this.swiperInstance.slideTo(2);
      this.package_bifurcation = true;
    },

    goToOtherIncome() {
      this.swiperInstance.slideTo(1);
    },
    async getServiceRevenueById(service_id, franchise_id) {
      try {
        const payload = {
          service_id: service_id,
          franchise_id: franchise_id,
        };
        const res = await CommonServices.getServiceRevenueById(payload);
        if (res && res.data.status) {
          console.log("servicesData", res);
        }
      } catch (error) {
        console.error("Error fetching service revenue:", error);
      }
    },
    async getMonthlyExpenses() {
      try {
        const payload = {
          month: this.me_month,
          year: this.me_year,
        };
        const res = await AgentServices.getMonthlyExpenses(payload);
        if (res.data.status) {
          const monthlyExpenses = res.data.data;
          let rent = 0;
          let salary = 0;
          let marketing = 0;
          let other = 0;
          let incentives = 0;
          for (let i = 0; i < monthlyExpenses.length; i++) {
            switch (monthlyExpenses[i].me_type) {
              case "rent":
                rent = rent + monthlyExpenses[i].me_amount;
                break;
              case "salary":
                salary = salary + monthlyExpenses[i].me_amount;
                break;
              case "incentives":
                incentives = incentives + monthlyExpenses[i].me_amount;
                break;
              case "marketing":
                marketing = marketing + monthlyExpenses[i].me_amount;
                break;
              case "other":
                other = other + monthlyExpenses[i].me_amount;
                break;
            }
          }

          this.monthlyExpenses = [
            {
              type: "Salary",
              amount: salary,
              icon: "DollarSignIcon",
            },
            {
              type: "Incentives",
              amount: incentives,
              icon: "TrendingUpIcon",
            },
            {
              type: "Rent",
              amount: rent,
              icon: "HomeIcon",
            },
            {
              type: "Marketing",
              amount: marketing,
              icon: "CastIcon",
            },
            {
              type: "Other",
              amount: other,
              icon: "PackageIcon",
            },
          ];
          const total = rent + salary + incentives + marketing + other;
          this.totalMonthlyExpenses = total;
        }
      } catch (error) {
        console.error("Error in getMonthlyExpenses ", error);
      }
    },
    refreshBarChart() {
      this.$refs.barChart.refreshBarChart();
    },
  },

  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"]?.user_type) {
        return store.getters["user/getUserDetails"].user_type;
      }
    },
    user_id() {
      if (store.getters["user/getUserDetails"]) {
        return store.getters["user/getUserDetails"].user_id;
      }
    },
  },
  watch: {
    me_month() {
      this.getMonthlyExpenses();
    },
    me_year() {
      this.getMonthlyExpenses();
    },
  },
  beforeMount() {
    this.getHomeData();

    this.getMonthlyExpenses();
    if (this.user_type) {
      this.getFranchiseStatistics();
    }
    // this.getLMSAccess();
  },
};
</script>

<style scoped>
.dashboard__heading {
  color: #002884;
  font-weight: bold;
  margin-bottom: 1.2rem;
}

.student-dashboard .card-title {
  color: #000000;
  font-weight: 600;
}

.primary-color {
  color: #00d09c;
}

.swiper-container {
  height: auto !important;
}

.vue-daterange-picker * span {
  display: none !important;
}

.filters {
  display: flex;
  gap: 10px;
}

.filters span {
  cursor: pointer;
  padding-bottom: 5px;
}

.filters span.active {
  border-bottom: 2px solid #00d09c;
}

.filter_text {
  font-size: smaller;
  font-weight: 700;
  opacity: 0.7;
  margin-left: 5px;
  padding-bottom: 2px;
}

.vue-daterange-picker[data-v-1ebd09d2] {
  position: relative;
  display: inline-block;
  min-width: 20px !important;
}

.b_card_earning {
  position: relative;
}

.b_header_earning {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 18px;
  right: 22px;
}

@media screen and (max-width: 1400px) {
  .b_header_earning {
    /* position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50px;
  right: 0px; */
  }
}

.custom-swiper-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.slide-content {
  text-align: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

/* Custom Arrows */
.custom-navigation {
  display: flex;
  justify-content: space-around;
  width: 100%;
  /* margin-bottom: 10px;; */
}

.custom-prev,
.custom-next {
  background: #d6e3f0;
  color: rgb(7, 7, 7);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0 5px;
}

.custom-prev:hover,
.custom-next:hover {
  cursor: pointer;
}

.swiper-pagination {
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.swiper-pagination-bullet {
  background-color: #92c5f9;
  width: 12px;
  height: 12px;
  opacity: 0.7;
  border-radius: 50%;
}

.swiper-pagination-bullet-active {
  background-color: #4394e5;
  opacity: 1;
}

.selected_filter {
  position: absolute;
  top: 22px;
  left: 120px;
}

@media screen and (max-width: 1200px) {
  .swiper-slide {
    width: 100% !important;
  }
}

@media screen and (max-width: 1600px) {
  .swiper-slide {
    width: 100% !important;
  }
}
@media screen and (max-width: 1400px) {
  .swiper-slide {
    width: 100% !important;
  }
}
</style>
