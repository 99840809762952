<template>
  <b-card>
    <template #header>
      <div style="font-size: larger; font-weight: 600; color: #605a75;" class="d-flex align-items-center">
        {{ date === "Lifetime" ? "Statistics" : "Current Statistics" }}
        <span style="font-size: small; font-weight: 500; margin-left: 10px;">
          {{ selectedFilter }}
        </span>
      </div>
    </template>
    <div class="b_header_earning">
      <div class="d-flex align-items-center justify-content-between">
        <div class="filters mr-2">
          <span
            v-for="filter in filters"
            :key="filter"
            :class="{ active: selectedFilterName === filter }"
            class="filter_text__"
            @click="selectFilter(filter)"
          >
            {{ filter }}
          </span>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center">
        <date-range-picker
          @update="handleDateUpdate"
          :locale-data="locale"
          :opens="opens"
          :ranges="ranges"
          :autoApply="autoApply"
          v-model="dateRange"
          class="date-range-picker"
        >
        </date-range-picker>
      </div>
    </div>
    <!-- <b-card-text class="mr-25 mb-0">Updated 1 month ago</b-card-text> -->
    <b-card-body class="">
      <b-row class="">
        <b-col
          v-for="(value, key) in statistics"
          :key="key"
          md="3"
          sm="4"
          class="pl-3 align-items-center mb-5"
        >
          <b-media no-body>
            <b-media-aside class="">
              <b-avatar size="52" :variant="value.color">
                <feather-icon size="24" :icon="value.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4
                class="font-weight-bolder font-large-1 mb-0"
                :class="{ 'cursor-pointer': value.value !== 0 }"
                @click="value.value !== 0 && redirectTo(value.subtitle)"

              >
                {{ value.value }}
              </h4>
              <b-card-text class="font-medium-3 mb-0">
                {{ value.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import AgentServices from "@/apiServices/AgentServices";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import CommonServices from "@/apiServices/CommonServices";
import store from "@/store";
import LmsServices from "@/apiServices/LmsServices";
import CounselorServices from "@/apiServices/CounselorServices";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    DateRangePicker,
  },
  data() {
    const startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    const endDate = new Date();
    return {
      statistics: {
        totalEvents: {
          icon: "CalendarIcon",
          color: "light-primary",
          value: 0,
          subtitle: "Events",
        },

        leadCounts: {
          icon: "UserIcon",
          color: "light-dark",
          value: 0,
          subtitle: "Lead ",
        },
        counseledCounts: {
          icon: "UsersIcon",
          color: "light-info",
          value: 0,
          subtitle: "Counseled ",
        },
        applicationCounts: {
          icon: "UserCheckIcon",
          color: "light-info",
          value: 0,
          subtitle: "Applications",
        },
        depositStudents: {
          icon: "DollarSignIcon",
          color: "light-success",
          value: 0,
          subtitle: "Deposits",
        },
        visaStudents: {
          icon: "GlobeIcon",
          color: "light-info",
          value: 0,
          subtitle: "Visa",
        },
        activeStudents: {
          icon: "UserCheckIcon",
          color: "light-info",
          value: 0,
          subtitle: "Active",
        },
        joinedStudents: {
          icon: "UserIcon",
          color: "light-info",
          value: 0,
          subtitle: "Joined",
        },
      },
      quarter: null,
      dateRange: {
        startDate: moment(startDate).toDate(),
        endDate: moment(endDate).toDate(),
      },
      date: `${moment(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      ).format("YYYY-MM-DD")} to ${moment(new Date()).format("YYYY-MM-DD")}`,

      opens: "left",
      locale: { firstDay: 1, format: " " },
      ranges: {
        Today: [new Date(), new Date()],
        "This Week": [
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay())
          ),
          new Date(),
        ],
        "Last Week": [
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay() - 7)
          ),
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay() - 1)
          ),
        ],
        "This Month": [
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          new Date(),
        ],
        "This Year": [
          new Date(new Date().getFullYear(), 0, 1),
          new Date(new Date().getFullYear(), 11, 31),
        ],
        "This Quarter": [
          new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3,
            1
          ),
          new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3 + 3,
            0
          ),
        ],
        Lifetime: [null, null],
      },
      autoApply: true,
      maxDate: new Date(),
      selectedFilterName: "This Month",
      selectedFilter:moment().format("MMMM"),
      filters: ["This Month", "This Quarter", "This Year", "Lifetime"],
      lead_count: 0,
      lmsApi: null,
      account_created_at: null,
      application_ids: [],
      deposits_ids: [],
      visa_ids: [],
      converted_ids: [],
      joined_ids: [],
      active_ids: [],
    };
  },

  watch: {
    user_type: {
      immediate: true,
      handler(newVal) {},
    },
    franchise_id: {
      immediate: true,
      handler(newVal) {
        if (this.user_type && this.user_type === "counselor") {
      this.getFranchiseStatistics({
        user_id: this.franchise_id,
        dateRange: this.date,
      });
    }
      },
    }

  },
  methods: {
    selectFilter(filter) {
      this.selectedFilterName = filter;

      // Determine the date range based on the selected filter
      let startDate, endDate;
      switch (filter) {
        case "This Month":
          startDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
          );
          endDate = new Date();
          this.dateRange = {
            startDate: moment(startDate).toDate(),
            endDate: moment(endDate).toDate(),
          };
          break;
        case "This Quarter":
          startDate = new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3,
            1
          );
          endDate = new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3 + 3,
            0
          );
          this.dateRange = {
            startDate: moment(startDate).toDate(),
            endDate: moment(endDate).toDate(),
          };
          this.quarter = `Q${Math.floor(new Date().getMonth() / 3) + 1}`;
          break;
        case "This Year":
          startDate = new Date(new Date().getFullYear(), 0, 1);
          endDate = new Date(new Date().getFullYear(), 11, 31);
          this.dateRange = {
            startDate: moment(startDate).toDate(),
            endDate: moment(endDate).toDate(),
          };
          break;
        case "Lifetime":
          startDate = null;
          endDate = null;
          break;
        default:
          return; // Avoid triggering API calls for unhandled filters
      }

      // Prepare the date range
      const dateRange = {
        startDate: startDate ? moment(startDate).toDate() : null,
        endDate: endDate ? moment(endDate).toDate() : null,
      };

      // Update the date range and make the API call
      this.handleDateUpdate(dateRange);
    },
    async getFranchiseStatistics(payload) {
      try {
        let res;
        if (this.user_type === "counselor") {
          res = await CounselorServices.getFranchiseStatistics(payload);
        }

        if (this.user_type === "franchise") {
          res = await AgentServices.getFranchiseStatistics(payload);
        }

        const data = res.data.data.statistics;
        this.statistics.totalEvents.value = data.totalEvents ? data.totalEvents : 0;
        this.statistics.depositStudents.value = data.deposit_students_count ? data.deposit_students_count.count : 0;
        this.statistics.counseledCounts.value = data.converted_students ? data.converted_students.counts: 0;
        this.statistics.visaStudents.value = data.visa_students_count ? data.visa_students_count.count : 0;
        this.statistics.applicationCounts.value = data.applications_count ? data.applications_count.count : 0;
        this.statistics.activeStudents.value = data.active_students ? data.active_students.count : 0;
        this.statistics.joinedStudents.value = data.joined_students ? data.joined_students.count : 0;


        this.application_ids = data.applications_count ? data.applications_count.application_user_ids : [];
        this.deposits_ids = data.deposit_students_count ? data.deposit_students_count.deposit_student_ids : [];
        this.visa_ids = data.visa_students_count ? data.visa_students_count.visa_student_ids : [];
        this.converted_ids = data.converted_students ? data.converted_students.converted_student_ids : [];
        this.joined_ids = data.joined_students ? data.joined_students.joined_student_ids : [];
        this.active_ids = data.active_students ? data.active_students.active_student_ids : [];

        this.account_created_at = res.data.data.created_at_date;
      } catch (error) {
        console.error("Error in getting franchise statistics:", error);
      }
    },
    handleDateUpdate(value) {
      const selectedRange = Object.entries(this.ranges).find(
        ([name, range]) =>
          range[0]?.toDateString() === value.startDate?.toDateString() &&
          range[1]?.toDateString() === value.endDate?.toDateString()
      );

      this.selectedFilterName = selectedRange
        ? selectedRange[0]
        : "Custom Range";
      if (value.startDate === null && value.endDate === null) {
        this.dateRange = { startDate: "", endDate: "" };
        this.date = "Lifetime";
      } else {
        const formattedStartDate = moment(value.startDate).format("YYYY-MM-DD");
        const formattedEndDate = moment(value.endDate).format("YYYY-MM-DD");
        this.dateRange = {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        };
        this.date = `${formattedStartDate} to ${formattedEndDate}`;
      }

      if (this.selectedFilterName === "This Month") {
        this.selectedFilter = moment().format("MMMM");
      }

      if (this.selectedFilterName === "This Quarter") {
        this.selectedFilter = `Q${Math.floor(new Date().getMonth() / 3) + 1}`;
      }

      if (this.selectedFilterName === "This Year") {
        this.selectedFilter = new Date().getFullYear();
      }
      if (this.selectedFilterName === "Lifetime") {
        this.selectedFilter = new Date(this.account_created_at).toLocaleString('default', { year: 'numeric', month: 'long' }) + " - " + new Date().toLocaleString('default', { year: 'numeric', month: 'long' });
      }

      this.onFilterChange();
    },
    onFilterChange() {
      const payload = { dateRange: this.date };
      this.getFranchiseStatistics(payload);
      this.getLMSAccess();
    },
    redirectTo(subtitle) {
      subtitle = subtitle.trim();
      switch (subtitle) {
        case "Events":
          this.$router.push({
            path: "/events",
            query: { action: "events" },
          });
          break;
        case "Lead":
          this.$router.push({
            path: "/my_leads",
            query: { subtitle: "lead" },
          });
          break;
        case "Counseled":
          this.$router.push({
            path: "/student_list",
            query: { conversion: "Y",student_ids: this.converted_ids },
          });
          break;
        case "Applications":
          this.$router.push({
            path: "/student_list",
            query: { application_status_id: 3,student_ids: this.application_ids },
          });
          break;
        case "Deposits":
          this.$router.push({
            path: "/student_list",
            query: { application_status_id: 5,student_ids: this.deposits_ids },
          });
          break;
        case "Visa":
          this.$router.push({
            path: "/student_list",
            query: { application_status_id: 8,student_ids: this.visa_ids },
          });
          break;
        case "Active":
          this.$router.push({
            path: "/student_list",
            query: { student_status: "active", student_ids: this.active_ids },
          });
          break;
        case "Joined":
          this.$router.push({
            path: "/student_list",
            query: { student_status: "joined",student_ids: this.joined_ids },
          });
          break;
        default:
          return;
      }
      // if (subtitle === "Lead" || subtitle === "Counseled") {
      //   this.$router.push({
      //     path: "/my_leads",
      //     query: { subtitle: subtitle.toLowerCase() },
      //   });
      // } else {
      //   this.$router.push({
      //     path: "/student_list",
      //     query: { subtitle: subtitle.toLowerCase() },
      //   });
      // }
    },
    async getMyLeads() {
      try {
        let res;
        if (this.date === "Lifetime") {
          res = await this.lmsApi.getMyLeads();
        } else {
          // console.log("Statssdate", this.date);
          res = await this.lmsApi.getMyLeads({
            registration_date: this.date,
          });
        }

        if (res.data.status) {
          this.statistics.leadCounts.value = res.data.data.total;
          // this.statistics.leadCounts.value = 500;
        }
      } catch (error) {
        console.error(`Error in getMyLeads `, error);
      }
    },
    async getLMSAccess() {
      try {
        const res = await CommonServices.getLMSAccessToken();
        this.accessToken = res.data.data;

        if (!this.accessToken) {
          this.accessTokenFailed = true;
          return;
        }

        this.lmsApi = LmsServices(this.accessToken);

        await this.getMyLeads();
        // await this.getMyCounseledLeads();
      } catch (error) {
        console.error(`Error in getLMSAccess `, error);
      }
    },
  },
  props: {
    franchise_id: {
      type: Number,
      default: null,
    },
    user_type: {
      type: String,
      default: null,
    },
  },
  beforeMount() {
    if (this.user_type && this.user_type === "counselor") {
      this.getFranchiseStatistics({
        user_id: this.franchise_id,
        dateRange: this.date,
      });
    } else {
      this.getFranchiseStatistics({ dateRange: this.date });
    }
    this.getLMSAccess();
  },
};
</script>
<style scoped>
.filter_text {
  font-size: small;
  font-weight: 700;
  opacity: 0.7;
  margin-left: 5px;
  padding-bottom: 2px;
}
.filters {
  display: flex;
  gap: 10px;
}

.filters span {
  cursor: pointer;
  padding-bottom: 5px;
}

.filters span.active {
  border-bottom: 2px solid #00d09c;
}
.filter_text__ {
  font-size: smaller;
  font-weight: 700;
  opacity: 0.7;
  margin-left: 5px;
  padding-bottom: 2px;
}
.vue-daterange-picker[data-v-1ebd09d2] {
  position: relative;
  display: inline-block;
  min-width: 20px !important;
}
.b_card_earning {
  position: relative;
}
.b_header_earning {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 18px;
  right: 22px;
}

.selected_filter {
  position: absolute;
  top: 22px;
  left: 120px;
}
</style>
