<template>
  <section>
    <!-- <b-alert v-model="showAlert" variant="danger" dismissible class="position-fixed fixed-top mx-auto mt-5 rounded-0"
      style="z-index: 1050; width: 40%; color: white !important">
      <div class="p-1">
        <h4 class="text-white">
          <strong>{{ notification.notification_name }}</strong>
        </h4>
        <p>{{ notification.notification_text }}</p>
      </div>
      <template #close>
        <b-button
          class="btn-close position-absolute top-0 end-0 mt-3 me-3 text-white"
          type="button"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </b-button>
      </template>
</b-alert> -->
    <b-row>
      <b-col v-if="user_type === 'counselor'">
        <b-card class="card-app-design assigned-staff-card">
          <h4>Student Registration Link</h4>
          <b-button
            class="btn btn-sm btn-outline-primary mr-1"
            @click="openStudentLink()"
          >
            <span class="mr-25 align-middle">Click Here</span>
          </b-button>
        </b-card>
      </b-col>

      <!-- <b-col cols="12" v-if="user_type === 'counselor'">
        <b-card>
          <b-table
            style="max-height: 60vh; cursor: pointer"
            :fields="tableColumns"
            :items="franchise_statistics"
            responsive
            sticky-header
            show-empty
            empty-text="No Data Available"
          >
            <template #cell(index)="data">{{ data.index + 1 }}</template>
            <template #cell(frachise_name)="data">{{
              data.item.franchise_name || 0
            }}</template>
            <template #cell(events)="data">{{
              data.item.franchise_events_counts || 0
            }}</template>
            <template #cell(lead_generation)="data">{{
              lead_count || 0
            }}</template>
            <template #cell(counseling)="data">{{
              counseled_count || 0
            }}</template>
            <template #cell(applications)="data">{{
              data.item.total_applications || 0
            }}</template>
            <template #cell(deposits)="data"
              >1{{ data.item.total_deposit_students || 0 }}</template
            >
            <template #cell(visa)="data">{{
              data.item.visa_students_count || 0
            }}</template>
            <template #cell(total_revenue)="data">{{
              data.item.total_revenue || 0
            }}</template>
          </b-table>
        </b-card>
      </b-col> -->

      <b-col v-if="user_type === 'operations_manager'">
        <ManagerTable />
      </b-col>

      <!-- Lead wise filter -->
      <b-col cols="12" v-if="user_type === 'counselor'">
        <b-card title="Filters">
          <b-row>
            <b-col>
              <b-form-group label="Lead Type">
                <v-select
                  placeholder="Lead Type"
                  v-model="leadType"
                  :options="leadTypeOptions"
                  :clearable="true"
                  item-text="label"
                  :reduce="(item) => item.value"
                  @input="
                    () => {
                      toggleShowManagers();
                      handleLeadSourceOptions();
                      getHomeData();
                    }
                  "
                />
              </b-form-group>
            </b-col>
            <!-- <b-col v-if="isSeniorManager">
              <b-form-group label="Managers">
                <v-select
                  placeholder="Select Managers"
                  v-model="manager_id"
                  :options="
                    managers.map((counselor) => ({
                      value: counselor.user_id,
                      label: `${counselor.user_firstname} ${counselor.user_lastname}`,
                    }))
                  "
                  :clearable="true"
                  item-text="label"
                  :reduce="(item) => item.value"
                  @input="
                    () => {
                      toggleShowManagers();
                      handleLeadSourceOptions();
                      getHomeData();
                    }
                  "
                />
              </b-form-group>
            </b-col> -->
            <b-col>
              <b-form-group label="Lead Source">
                <v-select
                  placeholder="Lead Source"
                  v-model="leadSource"
                  :disabled="leadType ? false : true"
                  :options="leadSourceOptions"
                  :clearable="true"
                  item-text="label"
                  :reduce="(item) => item.value"
                  @input="onSelectLeadSource"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!-- <b-col>
        <b-row v-if="showChart">
          <b-col cols="7">
            <b-card>
              <StatisticCard
                v-if="isEarningChartLoaded"
                :leadCounts="lead_count"
                :franchise_id="leadSource"
                :user_type="user_type"
              />
            </b-card>
          </b-col>
          <b-col cols="5">
            <b-card>
              <EarningChart :franchise_id="leadSource" :user_type="user_type" />
            </b-card>
          </b-col>
        </b-row>
    </b-col> -->

      <b-col cols="12" v-if="showChart && user_type === 'counselor'">
        <b-row class="mb-3">
          <b-col style="height: 440px" cols="8" xl="6">
            <b-card
              title="Earnings"
              class="b_card_earning"
              style="height: 100%"
            >
              <div class="selected_filter">
                <b>{{ selectedFilter }}</b>
              </div>

              <div class="b_header_earning">
                <div class="d-flex align-items-center justify-content-between">
                  <div></div>
                  <div class="filters mr-2">
                    <span
                      v-for="filter in filters"
                      :key="filter"
                      :class="{ active: selectedFilterName === filter }"
                      class="filter_text"
                      @click="selectFilter(filter)"
                    >
                      {{ filter }}
                    </span>
                  </div>
                </div>
                <div class="d-flex flex-column align-items-center">
                  <date-range-picker
                    @update="handleDateUpdate"
                    :locale-data="locale"
                    :opens="opens"
                    :ranges="ranges"
                    :autoApply="autoApply"
                    v-model="dateRange"
                    class="date-range-picker"
                  >
                  </date-range-picker>
                </div>
              </div>

              <swiper
                :modules="modules"
                :slides-per-view="1"
                :space-between="70"
                :pagination="{
                  el: '.swiper-pagination',
                  clickable: true,
                }"
                :navigation="{
                  nextEl: '.custom-next',
                  prevEl: '.custom-prev',
                }"
                v-if="user_type"
                ref="swiper"
                @swiper="onSwiper"
              >
                <swiper-slide class="">
                  <EarningChart
                    :series="series"
                    :totalEarnings="totalEarnings"
                    :isChartDataReady="isChartDataReady"
                    @goToOtherIncome="goToOtherIncome"
                  />
                </swiper-slide>
                <swiper-slide class="">
                  <ServiceBifuChart :date="date" @goToService="goToService" />
                </swiper-slide>
                <swiper-slide class="">
                  <PackagesChart :service_id="service_id" />
                </swiper-slide>
              </swiper>
              <div class="swiper-pagination"></div>
            </b-card>
          </b-col>
          <b-col style="height: 440px" cols="4" xl="6">
            <b-card title="Expenses" class="h-100">
              <div class="selected_filter">
                <b>{{ me_selectedFilter }}</b>
              </div>

              <div class="b_header_earning">
                <div class="d-flex align-items-center justify-content-between">
                  <div></div>
                  <div class="filters mr-2">
                    <span
                      v-for="filter in me_filters"
                      :key="filter"
                      :class="{ active: me_selectedFilterName === filter }"
                      class="filter_text"
                      @click="me_selectFilter(filter)"
                    >
                      {{ filter }}
                    </span>
                  </div>
                </div>
              </div>
              <b-tabs nav-class="">
                <b-tab>
                  <template #title class="p-0">
                    <h5 class="font-small-4">Expenses</h5>
                  </template>
                  <b-row class="mt-2">
                    <b-col
                      cols="12"
                      v-for="expense in monthlyExpenses"
                      :key="expense.id"
                    >
                      <div
                        class="
                          d-flex
                          align-items-center
                          justify-content-between
                          mb-1
                        "
                      >
                        <div class="d-flex align-items-baseline">
                          <feather-icon :icon="expense.icon" size="25" />
                          <h3 class="font-medium-4 ml-1" style="color: #333">
                            {{
                              expense.type
                                .replace(/_/g, " ")
                                .replace(/\w\S*/g, function (txt) {
                                  return (
                                    txt.charAt(0).toUpperCase() +
                                    txt.substr(1).toLowerCase()
                                  );
                                })
                            }}
                          </h3>
                        </div>
                        <h3 class="font-medium-2" style="">
                          Rs. {{ expense.amount }}
                        </h3>
                      </div>
                    </b-col>

                    <b-col cols="12">
                      <hr class="my-2 w-100" />
                    </b-col>
                    <b-col cols="12">
                      <div
                        class="
                          d-flex
                          align-items-center
                          justify-content-between
                          mb-1
                        "
                      >
                        <h3 class="font-medium-3" style="color: #333">Total</h3>
                        <h3 class="font-medium-2">
                          Rs. {{ totalMonthlyExpenses }}
                        </h3>
                      </div>
                    </b-col>
                  </b-row>
                </b-tab>

                <!-- <b-tab active>
                  <template #title class="p-0">
                    <h5 class="font-small-4">Add Expense</h5>
                  </template>
                  <MonthlyExpenses
                    :month="me_month"
                    :year="me_year"
                    :user_type="user_type"
                    :franchise_id="leadSource"
                  />
                </b-tab> -->
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <!-- <b-col cols="12" v-if="showChart && user_type === 'counselor'">
        <div>
          <StatisticCard
            :franchise_id="leadSource"
            :leadCounts="lead_count"
            :user_type="user_type"
          />
          <div v-if="!user_type">Loading Statistics .....</div>
        </div>
      </b-col> -->

      <!-- <b-col v-if="user_type === 'counselor'">
        <b-row>
          <b-col cols="12">
            <StatisticAggCard />
          </b-col>
        </b-row>
      </b-col> -->

      <!-- <b-col
        cols="12"
        v-if="
          (leadType == 'franchise' && leadSource) || user_type === 'franchise'
        "
      >
        <b-card
          :title="(user_type !== 'franchise' ? 'Franchise ' : '') + 'Filters'"
        >
          <div v-if="showAllLevelToggle()" class="mb-2">
            <div class="d-flex align-items-center mb-1">
              <p class="mr-1">
                Show All Level Data
                <small>{{ getStudentSupportDescription() }}</small>
              </p>
              <b-form-checkbox
                v-model="showAllLevelData"
                switch
                inline
                @input="onShowAllLevelData"
              />
            </div>
            <hr />
          </div>
          <b-row>
            <b-col
              cols="4"
              v-if="user_details.franchise_level === 'master' || master_f_id"
            >
              <b-form-group>
                <p style="margin-bottom: 5px">
                  Area Franchise
                  <small style="color: #aeaeae"
                    >(Select to get Micro Franchise)</small
                  >
                </p>
                <v-select
                  placeholder="Area Franchise"
                  v-model="area_f_id"
                  :options="area_franchises"
                  :clearable="true"
                  label="user_name"
                  :reduce="(item) => item.user_id"
                  @input="onSelectAreaFranchise"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="4"
              v-if="area_f_id || user_details.franchise_level === 'area'"
            >
              <b-form-group label="Micro Franchise">
                <v-select
                  placeholder="Micro Franchise"
                  v-model="micro_f_id"
                  :options="micro_franchises"
                  :clearable="true"
                  label="user_name"
                  :reduce="(item) => item.user_id"
                  @input="onSelectMicroFranchise"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col
              cols="4"
              v-if="
                (leadType === 'franchise' && leadSource) ||
                user_type === 'franchise'
              "
            >
              <b-form-group>
                <p style="margin-bottom: 5px">
                  Student Support
                  <small style="color: #aeaeae">{{
                    getStudentSupportDescription()
                  }}</small>
                </p>
                <v-select
                  placeholder="Student Support"
                  v-model="ss_id"
                  :options="ss_options"
                  :clearable="true"
                  label="user_name"
                  :reduce="(item) => item.user_id"
                  @input="onSelectStudentSupport"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col> -->

      <b-col cols="12" v-if="ss_id || area_f_id || micro_f_id || master_f_id">
        <agent-students
          :show_all_level_data="showAllLevelData"
          :lead="getLeadData"
          :agent_user_id="
            ss_id
              ? ss_id
              : micro_f_id
              ? micro_f_id
              : area_f_id
              ? area_f_id
              : master_f_id
          "
        />
      </b-col>

      <!-- Franchise responses -->
      <b-col
        cols="12"
        v-if="user_type === 'counselor' && quiz_responses && leadSource"
      >
        <quiz-responses
          :quiz_responses="quiz_responses"
          :franchise_id="leadSource"
        />
      </b-col>
       <b-col cols="12" v-if="user_type === 'counselor'">
        <BankApproval
          :franchise_id="leadSource"
          :banks="banks"
          @refreshData="getBanks"
        />
      </b-col> 

      <!-- Pyramid Chart -->
      <!-- <b-col cols="12">
        <pyramid-chart :data="pyramidChartData"></pyramid-chart>
      </b-col> -->

      <!-- Student Counts -->
      <!-- <b-col cols="12">
        <counts :data="studentCounts" header="Students" />
      </b-col> -->

      <!-- Students Assigned Line Graph -->
      <!-- <b-col cols="12">
        <e-chart
          :series="echartData.series"
          :xAxisData="echartData.xAxisData"
          @echartDateRange="getEchartDateRange"
        />
      </b-col> -->
    </b-row>

    <!-- Student Application Status Graph and list -->
    <b-card>
      <b-row>
        <b-col cols="5">
          <donut-chart
            :series="applicationsStatus"
            :labels="applicationsStatusLabels"
          />
        </b-col>
        <b-col cols="7">
          <applications-statuses
            @statusSelected="applicationStatusSelected"
            @applicationStatusDate="getApplicationStatusDate"
            :status_id="selectedStatusId"
            :data="applicationStatusCount"
            :status="selectedStatus"
          />
        </b-col>
        <b-col cols="12">
          <applications-wise-students
            :lead="getLeadData"
            :agent_user_id="micro_f_id ? micro_f_id : area_f_id"
            :status_id="selectedStatusId"
            :status="selectedStatus"
            :applicationStatusDate="applicationStatusDate"
          />
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BMediaAside,
  BMedia,
  BAvatar,
  BFormSelect,
  BButton,
  BFormGroup,
  BFormCheckbox,
  BTable,
  BAlert,
  BToast,
  BImg,
  BCardTitle,
  BCardHeader,
  BTabs,
  BTab,
} from "bootstrap-vue";
import ApplicationsStatuses from "@/views/components/StaffHomeComponents/ApplicationsStatuses.vue";
import DonutChart from "@/views/components/StaffHomeComponents/DonutChart.vue";
import EChart from "@/views/components/StaffHomeComponents/EChart.vue";
import ApplicationsWiseStudents from "@/views/components/StaffHomeComponents/ApplicationsWiseStudents.vue";
import CommonServices from "@/apiServices/CommonServices";
import Counts from "@/views/components/StaffHomeComponents/Counts.vue";
import PyramidChart from "./PyramidChart.vue";
import CounselorServices from "@/apiServices/CounselorServices";
import store from "@/store";
import { VueSelect } from "vue-select";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { FILESURL } from "@/config";
import vSelect from "vue-select";
import QuizResponses from "./QuizResponses.vue";
import AgentServices from "@/apiServices/AgentServices";
import AgentStudents from "@/views/components/StaffHomeComponents/AgentStudents.vue";
import ManagerTable from "./ManagerTable.vue";
import StatisticCard from "../AgentHome/Resources/StatisticCard.vue";
import EarningChart from "../AgentHome/Resources/EarningChart.vue";
import LmsServices from "@/apiServices/LmsServices";
import user from "@/store/user";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
import BarChart from "../AgentHome/Resources/BarChart.vue";
import PackagesChart from "../AgentHome/Resources/FranchiseTrainingVideos/PackagesChart.vue";
import { Navigation, Pagination } from "swiper";
SwiperCore.use([Navigation, Pagination]);
import ServiceBifuChart from "../AgentHome/Resources/ServiceBifuChart.vue";
import BankApproval from "./BankApproval.vue";
import StatisticAggCard from "./StatisticAggCard.vue";
import MonthlyExpenses from "../Settings/MonthlyExpenses.vue";

export default {
  components: {
    AgentStudents,
    ManagerTable,
    BAvatar,
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BCard,
    ApplicationsStatuses,
    DonutChart,
    EChart,
    ApplicationsWiseStudents,
    BMedia,
    BMediaAside,
    Counts,
    PyramidChart,
    VueSelect,
    BFormSelect,
    vSelect,
    BButton,
    QuizResponses,
    BFormGroup,
    BFormCheckbox,
    BTable,
    BAlert,
    BToast,
    BImg,
    StatisticCard,
    EarningChart,
    DateRangePicker,
    BCardTitle,
    BCardHeader,
    Swiper,
    SwiperSlide,
    ServiceBifuChart,
    BarChart,
    PackagesChart,
    BankApproval,
    StatisticAggCard,
    BTabs,
    BTab,
    MonthlyExpenses,
  },
  data() {
    const startDate = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    const endDate = new Date();
    return {
      leadType: "franchise",
      banks: [],
      leadSource: null,
      manager_id: null,
      leadTypeOptions: [
        { label: "Direct", value: "direct" },
        { label: "Associate", value: "agent" },
        { label: "Franchise", value: "franchise" },
      ],
      leadSourceOptions: [],
      isEarningChartLoaded: false,
      quiz_responses: [],
      lead_count: 0,
      FILESURL,
      applicationStatusCount: [],
      applicationsStatus: [],
      applicationsStatusLabels: [],
      applicationWiseStudents: [],
      selectedStatusId: null,
      selectedStatus: {},
      studentCounts: [],
      applicationStatusDate: {
        intake_month: null,
        intake_year: null,
      },
      echartDateRange: {
        startDate: "",
        endDate: "",
      },
      echartData: {
        xAxisData: [],
        series: [],
      },
      series: [0, 0],
      totalEarnings: 0,
      isChartDataReady: false,
      assignedAgents: [],
      selectedAgentUserId: "",
      pyramidChartData: [],
      baseURL: window.location.origin,
      area_franchises: [],
      micro_franchises: [],
      ss_options: [],
      master_f_id: null,
      area_f_id: null,
      micro_f_id: null,
      ss_id: null,

      showAllLevelData: false,
      currentFranchiseLevel: null,
      lead_count: 0,
      counseled_count: 0,
      franchise_statistics: [],
      showAlert: false,
      notification: {},
      senior_manager: false,
      showManagers: false,
      managers: [],
      showChart: false,
      alertMessage: "Dismissible Alert!",
      tableColumns: [
        {
          key: "franchise_name",
          label: "Franchise",
          sortable: false,
          // show: true,
        },
        {
          key: "events",
          label: "Events",
          sortable: false,
          // show: true,
        },
        {
          key: "lead_generation",
          label: "Lead Generation",
          sortable: false,
          // show: true,
        },
        {
          key: "counseling",
          label: "Counseling",
          sortable: false,
          // show: true,
        },
        {
          key: "applications",
          label: "Applications",
          sortable: false,
          // show: true,
        },
        {
          key: "deposits",
          label: "Deposits",
          sortable: false,
          // show: true,
        },
        {
          key: "visa",
          label: "Visa",
          sortable: false,
          // show: true,
        },
        {
          key: "service_commision",
          label: "Service Commision",
          sortable: false,
          // show: true,
        },
        {
          key: "total_revenue",
          label: "Commision Earned",
          sortable: false,
          // show: true,
        },
      ],
      maxDate: new Date(),
      ranges: {
        Today: [new Date(), new Date()],
        "This Week": [
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay())
          ),
          new Date(),
        ],
        "Last Week": [
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay() - 7)
          ),
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay() - 1)
          ),
        ],
        "This Month": [
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          new Date(),
        ],
        "This Year": [
          new Date(new Date().getFullYear(), 0, 1),
          new Date(new Date().getFullYear(), 11, 31),
        ],
        "This Quarter": [
          new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3,
            1
          ),
          new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3 + 3,
            0
          ),
        ],
        Lifetime: [null, null],
      },
      opens: "left",
      locale: { firstDay: 1, format: " " },
      autoApply: true,
      date: `${moment(
        new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      ).format("YYYY-MM-DD")} to ${moment(new Date()).format("YYYY-MM-DD")}`,
      dateRange: {
        startDate: moment(startDate).toDate(),
        endDate: moment(endDate).toDate(),
      },
      selectedFilterName: "This Month",
      selectedFilter: moment().format("MMMM"),
      filters: ["This Month", "This Quarter", "This Year", "Lifetime"],
      service_id: null,
      swiperInstance: null,
      modules: [Navigation, Pagination],
      monthlyExpenses: [],
      totalMonthlyExpenses: 0,
      me_selectedFilterName: "This Month",
      me_selectedFilter: moment().format("MMMM"),
      me_filters: ["This Month", "This Quarter", "This Year", "Lifetime"],
      me_month: moment().format("MMMM"),
      me_year: new Date().getFullYear(),
      account_created_at: null,
    };
  },

  computed: {
    user_type() {
      const val = store.getters["user/getUserDetails"].user_type;
      if (val === "counselor") {
      } else if (val === "franchise") {
        const fl = store.getters["user/getUserDetails"].franchise_level;

        // this.leadType = "franchise";
        this.franchise_level = fl;
        this.currentFranchiseLevel = fl;

        // if (fl === "master") {
        //   this.getAreaFranchises();
        // } else if (fl === "area") {
        //   this.getMicroFranchises(store.getters["user/getUserDetails"].user_id);
        // }

        // this.getStudentSupportList();
      }
      return val;
    },
    isSeniorManager() {
      return this.senior_manager;
    },
    user_details() {
      return store.getters["user/getUserDetails"];
    },
  },

  methods: {
    moment,
    onChange() {
      this.$nextTick(() => {
        this.getHomeData();
      });
    },

    async getLMSAccess() {
      try {
        const res = await CommonServices.getLMSAccessToken();
        this.accessToken = res.data.data;

        if (!this.accessToken) {
          this.accessTokenFailed = true;
          return;
        }

        this.lmsApi = LmsServices(this.accessToken);

        await this.getMyLeads();
      } catch (error) {
        console.error(`Error in getLMSAccess `, error);
      }
    },

    getTopLevelFranchise() {
      if (this.area_f_id) {
        return this.area_f_id;
      }

      if (this.user_type === "franchise") {
        return this.user_details.user_id;
      } else if (
        this.user_type === "counselor" &&
        this.leadType === "franchise" &&
        this.leadSource
      ) {
        return this.leadSource;
      }

      return null;
    },
    selectFilter(filter) {
      this.selectedFilterName = filter;

      let startDate, endDate;
      switch (filter) {
        case "This Month":
          startDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
          );
          endDate = new Date();
          this.dateRange = {
            startDate: moment(startDate).toDate(),
            endDate: moment(endDate).toDate(),
          };
          break;
        case "This Quarter":
          startDate = new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3,
            1
          );
          endDate = new Date(
            new Date().getFullYear(),
            Math.floor(new Date().getMonth() / 3) * 3 + 3,
            0
          );
          this.dateRange = {
            startDate: moment(startDate).toDate(),
            endDate: moment(endDate).toDate(),
          };
          this.quarter = `Q${Math.floor(new Date().getMonth() / 3) + 1}`;
          break;
        case "This Year":
          startDate = new Date(new Date().getFullYear(), 0, 1);
          endDate = new Date(new Date().getFullYear(), 11, 31);
          this.dateRange = {
            startDate: moment(startDate).toDate(),
            endDate: moment(endDate).toDate(),
          };
          break;
        case "Lifetime":
          startDate = null;
          endDate = null;
          break;
        default:
          return;
      }

      const dateRange = {
        startDate: startDate ? moment(startDate).toDate() : null,
        endDate: endDate ? moment(endDate).toDate() : null,
      };

      this.handleDateUpdate(dateRange);
    },
    me_selectFilter(filter) {
      this.me_selectedFilterName = filter;

      let month;
      let year = new Date().getFullYear();
      switch (filter) {
        case "This Month":
          month = new Date().toLocaleString("default", { month: "long" });
          this.me_selectedFilter = month;
          break;
        case "This Quarter":
          const currentMonth = new Date().getMonth();
          if (currentMonth >= 0 && currentMonth <= 2) {
            month = "January,February,March";
          } else if (currentMonth >= 3 && currentMonth <= 5) {
            month = "April,May,June";
          } else if (currentMonth >= 6 && currentMonth <= 8) {
            month = "July,August,September";
          } else if (currentMonth >= 9 && currentMonth <= 11) {
            month = "October,November,December";
          }
          this.me_selectedFilter = `Q${
            Math.floor(new Date().getMonth() / 3) + 1
          }`;
          break;
        case "This Year":
          month = null;
          this.me_selectedFilter = year;
          break;
        case "Lifetime":
          month = null;
          year = null;
          this.me_selectedFilter =
            new Date(this.account_created_at).toLocaleString("default", {
              year: "numeric",
              month: "short",
            }) +
            " - " +
            new Date().toLocaleString("default", {
              year: "numeric",
              month: "short",
            });
          break;
        default:
          return;
      }
      this.me_month = month;
      this.me_year = year;
    },
    async getMonthlyExpenses() {
      try {
        const payload = {
          month: moment().format("MMMM"),
          year: new Date().getFullYear(),
          user_id: this.leadSource,
        };
        const res = await CounselorServices.getMonthlyExpenses(payload);
        if (res.data.status) {
          const monthlyExpenses = res.data.data;
          let rent = 0;
          let salary = 0;
          let marketing = 0;
          let incentives = 0;
          let other = 0;
          for (let i = 0; i < monthlyExpenses.length; i++) {
            switch (monthlyExpenses[i].me_type) {
              case "rent":
                rent = rent + monthlyExpenses[i].me_amount;
                break;
              case "salary":
                salary = salary + monthlyExpenses[i].me_amount;
                break;
              case "marketing":
                marketing = marketing + monthlyExpenses[i].me_amount;
                break;
              case "incentives":
                incentives = incentives + monthlyExpenses[i].me_amount;
                break;
              case "other":
                other = other + monthlyExpenses[i].me_amount;
                break;
            }
          }

          this.monthlyExpenses = [
            {
              type: "Rent",
              amount: rent,
              icon: "HomeIcon",
            },
            {
              type: "Salary",
              amount: salary,
              icon: "DollarSignIcon",
            },
            {
              type: "Marketing",
              amount: marketing,
              icon: "CastIcon",
            },
            {
              type: "Other",
              amount: other,
              icon: "PackageIcon",
            },
          ];
          const total = rent + salary + marketing + other;
          this.totalMonthlyExpenses = total;
        }
      } catch (error) {
        console.error("Error in getMonthlyExpenses ", error);
      }
    },
    openStudentLink() {
      const link = `${this.baseURL}/register?refer_user_id=${store.getters["user/getUserDetails"].user_id}`;
      window.open(link, "_blank");
    },

    showAllLevelToggle() {
      if (
        this.micro_f_id ||
        (this.user_type === "franchise" &&
          this.user_details.franchise_level === "micro")
      )
        return false;
      return true;
    },

    async checkSeniorManager() {
      const res = await CounselorServices.checkSeniorManager();
      if (res.data.status) {
        this.senior_manager = res.data.data.is_senior_manager;
        if (this.senior_manager) {
          this.getAllTeamManagers();
        }
      }
    },

    async getAllTeamManagers() {
      try {
        const res = await CounselorServices.getAllTeamManagers();
        if (res.data.status) {
console.log("Manager",res);
          this.managers = res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async toggleShowManagers() {
      if (this.leadType === "franchise") {
        this.showManagers = true;
        this.getAllTeamManagers();
      }
    },

    onShowAllLevelData(val) {
      this.showAllLevelData = val;
      this.getHomeData();
    },

    resetValues() {
      this.applicationStatusCount = [];
      this.pplicationsStatus = [];
      this.applicationsStatusLabels = [];
      this.applicationWiseStudents = [];
      this.selectedStatusId = null;
      this.selectedStatus = {};
      this.studentCounts = [];
      this.applicationStatusDate = {
        intake_month: null,
        intake_year: null,
      };
      this.echartDateRange = {
        startDate: "",
        endDate: "",
      };
      this.echartData = {
        xAxisData: [],
        series: [],
      };

      this.assignedAgents = [];
      this.selectedAgentUserId = "";
      this.pyramidChartData = [];
    },

    async handleLeadSourceOptions() {
      this.leadSource = null;

      this.area_f_id = null;
      this.micro_f_id = null;
      this.master_f_id = null;
      this.ss_options = [];
      this.area_franchises = [];
      this.micro_franchises = [];

      if (!this.leadType) {
        this.leadSource = null;
      }
      if (this.leadType === "direct") {
        this.leadSourceOptions = [
          {
            label: "Facebook",
            value: "facebook",
          },
          {
            label: "Instagram",
            value: "instagram",
          },
          {
            label: "Aliff Website",
            value: "aliffWebsite",
          },
          {
            label: "Alumni",
            value: "alumni",
          },
        ];
      }
      if (this.leadType === "agent") {
        const res = await CounselorServices.getAssignedAgents({
          leadUserType: this.leadType,
        });
        let assignedAgents = res.data.data;
        this.leadSourceOptions = [];
        for (let assignedAgent of assignedAgents) {
          this.leadSourceOptions.push({
            label:
              assignedAgent.user_firstname + " " + assignedAgent.user_lastname,
            value: assignedAgent.user_id,
          });
        }
      }
      if (this.leadType === "franchise") {
        const res = await CommonServices.getAssignedAgents({
          leadUserType: this.leadType,
          manager_id: this.manager_id,
        });
        let assignedAgents = res.data.data;
        this.leadSourceOptions = [];
        for (let assignedAgent of assignedAgents) {
          this.leadSourceOptions.push({
            label:
              assignedAgent.user_firstname + " " + assignedAgent.user_lastname,
            value: assignedAgent.user_id,
            franchise_level: assignedAgent.franchise_level,
          });
        }
      }
    },

    onSelectLeadSource(val) {
      this.master_f_id = null;
      this.area_f_id = null;
      this.micro_f_id = null;
      this.ss_id = null;
      this.ss_options = [];
      this.area_franchises = [];
      this.micro_franchises = [];

      if (!val) {
        this.getHomeData();
        return;
      }

      this.$nextTick(() => {
        if (this.leadType == "franchise") {
          this.isEarningChartLoaded = true;
          this.showChart = true;
          this.getStudentSupportList();
          this.getMyLeads();
          this.getFranchiseStatistics();

          this.getMonthlyExpenses();

          const f = this.leadSourceOptions.find((i) => i.value === val);
          if (f.franchise_level) {
            this.currentFranchiseLevel = f.franchise_level;
            if (f.franchise_level === "master") {
              this.master_f_id = val;
              this.getAreaFranchises();
            } else if (f.franchise_level === "area") {
              this.area_f_id = val;
              this.getMicroFranchises(val);
            } else if (f.franchise_level === "micro") {
              this.micro_f_id = val;
            }
          }
        }

        this.getHomeData();
      });
    },

    async getBanks() {
      try {
        // const franchise_id = this.leadSource
        const res = await CounselorServices.getBankByFranchiseId();
        if (res.data.status) {
          this.banks = res.data.data;
        }
      } catch (error) {
        console.error("Error in getAllBanks ", error);
      }
    },
    async getAreaFranchises() {
      try {
        let res;
        if (this.user_type === "franchise") {
          res = await AgentServices.getAreaFranchises();
        } else if (this.user_type === "counselor") {
          res = await CounselorServices.getAreaFranchise({
            master_f_id: this.master_f_id,
          });
        }

        if (res && res.data.status) {
          this.area_franchises = res.data.data;
        } else {
          console.error(`false in getAreaFranchises `);
        }
      } catch (error) {
        console.error(`Error in getAreaFranchises `, error);
      }
    },

    async getMicroFranchises(area_f_id) {
      try {
        let res;
        if (this.user_type === "franchise") {
          res = await AgentServices.getMicroFranchises({ area_f_id });
        } else if (this.user_type === "counselor") {
          res = await CounselorServices.getMicroFranchise({ area_f_id });
        }

        if (res && res.data.status) {
          this.micro_franchises = res.data.data;
        }
      } catch (error) {
        console.error(`Error in getMicroFranchises `, error);
      }
    },

    async getStudentSupportList() {
      try {
        let res;
        if (this.user_type === "franchise") {
          res = await AgentServices.getAllStudentSupportList({
            area_f_id: this.area_f_id,
            micro_f_id: this.micro_f_id,
          });
        }

        if (
          this.user_type === "counselor" &&
          this.leadType === "franchise" &&
          (this.leadSource || this.area_f_id || this.micro_f_id)
        ) {
          res = await CounselorServices.getAllStudentSupportList({
            franchise_user_id: this.leadSource,
            area_f_id: this.area_f_id,
            micro_f_id: this.micro_f_id,
          });
        }

        if (res && res.data.status) {
          this.ss_options = res.data.data;
        }
      } catch (error) {
        console.error(`Error in getStudentSupportList `, error);
      }
    },

    async onSelectAreaFranchise(area_f_id) {
      this.getMicroFranchises(area_f_id);
      this.currentFranchiseLevel = "area";
      if (this.area_f_id === null) {
        this.currentFranchiseLevel = "master";
      }
      this.micro_f_id = null;

      this.$nextTick(() => {
        this.getStudentSupportList();
        this.getHomeData();
      });
    },

    async onSelectMicroFranchise() {
      this.ss_id = null;
      this.showAllLevelData = false;
      this.currentFranchiseLevel = "micro";
      if (this.micro_f_id === null) {
        this.currentFranchiseLevel = "area";
      }

      this.$nextTick(() => {
        this.getStudentSupportList();
        this.getHomeData();
      });
    },

    onSelectStudentSupport() {
      this.$nextTick(() => this.getHomeData());
    },

    applicationStatusSelected(item) {
      this.selectedStatusId = item.status_id;
      this.selectedStatus = item;
    },

    getApplicationStatusDate(date) {
      this.applicationStatusDate = date;
      this.getHomeData();
    },

    getEchartDateRange({ startDate, endDate }) {
      this.echartDateRange.startDate = startDate;
      this.echartDateRange.endDate = endDate;
      this.getHomeData();
    },

    getLeadData() {
      // if (this.user_type === "franchise") return null;
      return {
        leadType: this.leadType,
        leadSource: this.leadType == "direct" ? this.leadSource : null,
        agent_user_id:
          this.leadType !== "direct"
            ? this.ss_id
              ? this.ss_id
              : this.micro_f_id
              ? this.micro_f_id
              : this.area_f_id
              ? this.area_f_id
              : this.leadSource
            : null,
        franchise_level: this.currentFranchiseLevel,
      };
    },

    getStudentSupportDescription() {
      let franchiseName = "";

      if (this.micro_f_id && this.micro_franchises.length) {
        franchiseName =
          this.micro_franchises.find((item) => item.user_id === this.micro_f_id)
            ?.user_name || "";
      } else if (this.area_f_id && this.area_franchises.length) {
        franchiseName =
          this.area_franchises.find((item) => item.user_id === this.area_f_id)
            ?.user_name || "";
      } else {
        if (this.user_type === "franchise") {
          franchiseName =
            this.user_details.user_firstname +
            " " +
            this.user_details.user_lastname;
        } else if (this.user_type === "counselor") {
          franchiseName = this.leadSourceOptions.find(
            (leadSource) => leadSource.value === this.leadSource
          ).label;
        }
      }

      if (franchiseName) {
        return `(For ${franchiseName})`;
      }
      return "";
    },

    async getHomeData() {
      try {
        const res = await CommonServices.getHomeData({
          application: {
            ...this.applicationStatusDate,
          },
          // agent_user_id: this.selectedAgentUserId,
          echart: {
            ...this.echartDateRange,
          },
          lead: this.getLeadData(),
          ss_id: this.ss_id,

          showAllLevelData: this.showAllLevelData
            ? this.showAllLevelData
            : null,
        });
        if (res.data.status) {
          this.studentCounts = res.data.data.studentCounts;

          this.echartData.xAxisData =
            res.data.data.formattedMonthlyStudentsJoined.xAxisData;
          this.echartData.series =
            res.data.data.formattedMonthlyStudentsJoined.seriesData;

          const applicationStatusCount = res.data.data.applicationStatusCount;

          this.applicationStatusCount = applicationStatusCount;

          this.selectedStatusId = applicationStatusCount[0].status_id;
          this.selectedStatus = applicationStatusCount[0];

          this.pyramidChartData = res.data.data.pyramidChartData;

          if (applicationStatusCount.length > 1) {
            this.applicationsStatus = applicationStatusCount.map(
              (i) => i.count
            );

            this.applicationsStatusLabels = applicationStatusCount.map(
              (i) => i.status_name
            );
          }

          this.quiz_responses = [];
          if (
            this.leadType !== "direct" &&
            this.leadSource &&
            this.user_type === "counselor"
          ) {
            const res = await CounselorServices.getFranchiseQuizResponse({
              agent_user_id: this.leadSource,
            });

            if (res.data.status) {
              this.quiz_responses = res.data.data;
            }
          }
        } else {
          this.resetValues();
        }
      } catch (error) {
        console.error(`Error in getHomeData `, error);
      }
    },
    async warningNotification() {
      try {
        const res = await CommonServices.getWarningNotification();
        if (res.data.status) {
          this.notification = res.data.data;
        }
      } catch (error) {
        console.error(`Error in warningNotification `, error);
      }
    },
    async getMyLeads() {
      try {
        const [startDate, endDate] = this.date.split(" to ");

        const formattedStartDate = moment(startDate).format("DD-MM-YYYY");
        const formattedEndDate = moment(endDate).format("DD-MM-YYYY");

        const payload = {
          registration_date: `${formattedStartDate} to ${formattedEndDate}`,
        };

        const res = await this.lmsApi.getMyLeads(payload);
        if (res.data.status) {
          this.lead_count = res.data.data.total;
        }
      } catch (error) {
        console.error(`Error in getMyLeads `, error);
      }
    },
    async getFranchiseStatistics() {
      try {
        this.isChartDataReady = false;
        const payload = { dateRange: this.date };
        let res;
        if (this.user_type === "counselor") {
          res = await CounselorServices.getFranchiseStatistics({
            user_id: this.leadSource,
            ...payload,
          });
        }
        if (this.user_type === "franchise") {
          res = await AgentServices.getFranchiseStatistics(payload);
        }

        this.franchise_statistics = res.data.data.statistics;
        this.series = [
          this.franchise_statistics.total_aliff_commission,
          this.franchise_statistics.total_service_commission,
        ];

        this.totalEarnings =
          this.franchise_statistics.total_aliff_commission +
          this.franchise_statistics.total_service_commission;

        this.account_created_at = res.data.data.created_at_date;

        this.isChartDataReady = true;
      } catch (error) {
        console.log("Error in getting getFranchiseStatistics Agent ", error);
      }
    },

    handleDateUpdate(value) {
      const selectedRange = Object.entries(this.ranges).find(
        ([name, range]) =>
          range[0]?.toDateString() === value.startDate?.toDateString() &&
          range[1]?.toDateString() === value.endDate?.toDateString()
      );
      this.selectedFilterName = selectedRange
        ? selectedRange[0]
        : "Custom Range";

      if (value.startDate === null && value.endDate === null) {
        this.dateRange = { startDate: "", endDate: "" };
        this.date = "Lifetime";
      } else {
        const formattedStartDate = moment(value.startDate).format("YYYY-MM-DD");
        const formattedEndDate = moment(value.endDate).format("YYYY-MM-DD");
        this.dateRange = {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        };
        this.date = `${formattedStartDate} to ${formattedEndDate}`;
      }

      if (this.selectedFilterName === "This Month") {
        this.selectedFilter = moment().format("MMMM");
      }

      if (this.selectedFilterName === "This Quarter") {
        this.selectedFilter = `Q${Math.floor(new Date().getMonth() / 3) + 1}`;
      }

      if (this.selectedFilterName === "This Year") {
        this.selectedFilter = new Date().getFullYear();
      }
      if (this.selectedFilterName === "Lifetime") {
        this.selectedFilter =
          new Date(this.account_created_at).toLocaleString("default", {
            year: "numeric",
            month: "short",
          }) +
          " - " +
          new Date().toLocaleString("default", {
            year: "numeric",
            month: "short",
          });
      }

      this.getFranchiseStatistics();
    },
    onFilterChange() {
      this.$nextTick(() => {
        this.getFranchiseStatistics();
        this.getMyLeads();
      });
    },
    onSwiper(swiper) {
      this.swiperInstance = swiper; // Save the Swiper instance when initialized
    },
    goToService(service_id) {
      this.service_id = service_id;
      this.swiperInstance.slideTo(2);
      this.package_bifurcation = true;
    },

    async getServiceRevenueById(service_id, franchise_id) {
      try {
        service_id = 8;
        franchise_id = 91;
        const payload = {
          service_id: service_id,
          franchise_id: franchise_id,
        };
        const res = await CommonServices.getServiceRevenueById(payload);
        if (res && res.data.status) {
          // console.log("servicesData", res);
        }
      } catch (error) {
        console.error("Error fetching service revenue:", error);
      }
    },
    goToOtherIncome() {
      this.swiperInstance.slideTo(1);
    },

    goToService(service_id) {
      this.service_id = service_id;
      this.swiperInstance.slideTo(2);
      this.package_bifurcation = true;
    },

    goToOtherIncome() {
      this.swiperInstance.slideTo(1);
    },
    async getServiceRevenueById(service_id, franchise_id) {
      try {
        const payload = {
          service_id: service_id,
          franchise_id: franchise_id,
        };
        const res = await CommonServices.getServiceRevenueById(payload);
        if (res && res.data.status) {
          // console.log("servicesData", res);
        }
      } catch (error) {
        console.error("Error fetching service revenue:", error);
      }
    },
  },

  watch: {
    user_type(newVal, oldVal) {
      if (newVal !== undefined) {
        if (newVal === "franchise") {
          this.getStudentSupportList();
          this.getHomeData();
        }
      }
    },

    user_details(newVal, oldVal) {
      if (newVal !== undefined) {
        if (newVal.user_type === "franchise") {
          if (newVal.franchise_level) {
            if (newVal.franchise_level === "master") {
              this.master_f_id = newVal.user_id;
            } else if (newVal.franchise_level === "area") {
              this.area_f_id = newVal.user_id;
            } else if (newVal.franchise_level === "micro") {
              this.micro_f_id = newVal.user_id;
            }
          }
        }
      }
    },

    leadNotification() {},
  },
  watch: {
    showAlert(value) {
      if (!value) {
        localStorage.setItem("show_alert", false);
      }
    },
    user_type(newVal) {
      if (newVal === "counselor") {
        this.handleLeadSourceOptions();
        this.checkSeniorManager();
        this.getBanks();

      }
  },
  },
  beforeMount() {
    this.getHomeData();
    this.warningNotification();
    this.getLMSAccess();
    // const storedValue = localStorage.getItem("show_alert");
    // this.showAlert = storedValue === "true";
  },

  mounted() {
    if(this.user_type === "counselor"){
      this.handleLeadSourceOptions();
        this.checkSeniorManager();
        this.getBanks();
    }
  }
 
};
</script>

<style scoped>
.alert-danger {
  background: rgba(234, 84, 85, 0.8) !important;
  color: #fff !important;
  border-radius: 10px !important;
}

.alert.alert-dismissible .close {
  padding: 1.5rem 1rem 0.5rem 1rem !important;
  background-color: transparent !important;
  box-shadow: none !important;
  color: #fff !important;
  font-size: xx-large;
}

.swiper-container {
  height: auto !important;
}

.vue-daterange-picker * span {
  display: none !important;
}

.filters {
  display: flex;
  gap: 10px;
}

.filters span {
  cursor: pointer;
  padding-bottom: 5px;
}

.filters span.active {
  border-bottom: 2px solid #00d09c;
}

.filter_text {
  font-size: smaller;
  font-weight: 700;
  opacity: 0.7;
  margin-left: 5px;
  padding-bottom: 2px;
}

.vue-daterange-picker[data-v-1ebd09d2] {
  position: relative;
  display: inline-block;
  min-width: 20px !important;
}

.b_card_earning {
  position: relative;
}

.b_header_earning {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 18px;
  right: 22px;
}

.custom-swiper-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.slide-content {
  text-align: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
}

/* Custom Arrows */
.custom-navigation {
  display: flex;
  justify-content: space-around;
  width: 100%;
  /* bottom: 30px; */
}

.custom-prev,
.custom-next {
  background: #d6e3f0;
  color: rgb(7, 7, 7);
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0 5px;
}

.custom-prev:hover,
.custom-next:hover {
  cursor: pointer;
}

.swiper-pagination {
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.swiper-pagination-bullet {
  background-color: #92c5f9;
  width: 12px;
  height: 12px;
  opacity: 0.7;
  border-radius: 50%;
}

.swiper-pagination-bullet-active {
  background-color: #4394e5;
  opacity: 1;
}
.selected_filter {
  position: absolute;
  top: 22px;
  left: 120px;
}
</style>
