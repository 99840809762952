<template>
  <div class="bar_chart">
    <b-modal
      id="revenue-modal"
      title="Enter your annual target"
      hide-footer
      centered
      size="md"
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-form>
        <b-form-group
          label-for="total-profit"
          invalid-feedback="Profit is required"
        >
          <label class="font-weight-bold" style="font-size: medium"
            >Profit:</label
          >
          <b-form-input
            id="total-profit"
            v-model="revenueForm.at_profit"
            type="number"
            required
            placeholder="Enter Profit"
          />
        </b-form-group>

        <b-form-group
          label-for="total-revenue"
          invalid-feedback="Revenue is required"
        >
          <label class="font-weight-bold" style="font-size: medium"
            >Revenue:</label
          >
          <b-form-input
            id="total-revenue"
            v-model="revenueForm.at_revenue"
            type="number"
            required
            placeholder="Enter Revenue"
          />
        </b-form-group>

        <b-form-group
          label-for="total-students"
          invalid-feedback="Students is required"
        >
          <label class="font-weight-bold" style="font-size: medium"
            >Students:</label
          >
          <b-form-input
            id="total-students"
            v-model="revenueForm.at_students"
            type="number"
            required
            placeholder="Enter Students"
          />
        </b-form-group>

        <b-form-group
          label-for="total-partner"
          invalid-feedback="Partner is required"
        >
          <label class="font-weight-bold" style="font-size: medium"
            >Leads:</label
          >
          <b-form-input
            id="total-leads"
            v-model="revenueForm.at_leads"
            type="number"
            required
            placeholder="Enter Leads"
          />
        </b-form-group>

        <b-button
          variant="primary"
          class="w-75 d-block mx-auto mt-2 mb-1"
          @click="openAreYouSureModal"
          >Submit</b-button
        >
      </b-form>
    </b-modal>
    <b-modal
      id="are-you-sure"
      title="Are you sure?"
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
    >
      <p class="">
        This targets cannot be changed later. Are you sure you want to submit?
      </p>
      <b-button
        variant="success"
        class="w-25 d-block mx-auto mt-2 mb-1"
        @click="addAnnualTargets"
        >Yes</b-button
      >
    </b-modal>

    <div class="form_parent">
      <b-row>
        <b-col cols="6">
          <b-card title="This year's mission">
            <apex-charts
              type="bar"
              height="350"
              v-if="isChartDataReady"
              :options="revenue_chartOptions"
              :series="revenue_series"
              :toolbar="{ show: false }"
            />
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card title="This year's mission">
            <apex-charts
              type="bar"
              height="350"
              v-if="isChartDataReady"
              :options="leads_chartOptions"
              :series="leads_series"
              
            />
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import AgentServices from "@/apiServices/AgentServices";
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BModal,
  BRow,
} from "bootstrap-vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import ApexCharts from "vue-apexcharts";
import CommonServices from "@/apiServices/CommonServices";
import LmsServices from "@/apiServices/LmsServices";
import moment from "moment";

export default {
  components: {
    ApexCharts,
    BModal,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
  },
  data() {
    const revenue_targets = {};
    const revenue_achieved = {};
    const revenue_pending = [];

    const leads_targets = {};
    const leads_achieved = {};
    const leads_pending = [];

    return {
      isChartDataReady: true,
      showForm: false,
      lmsApi: null,
      leads_achieved: 0,
      revenue_series: [],
      leads_series: [],

      revenue_chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "45%",
            borderRadius: 5,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return `₹${val.toLocaleString("en-IN")}`;
          },
          style: {
            fontSize: "10px",
            colors: ["#333"],
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: Object.keys(revenue_targets).map((key) =>
            key.toLocaleString("en-IN")
          ),
          labels: {
            style: {
              fontSize: "17px",
            },
          },
        },
        yaxis: {
          title: {
            text: "Values in ₹",
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return `₹${val.toLocaleString("en-IN")}`;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
        colors: ["#00E396", "#F72C5B"],
      },

      // Second Chart
      leads_chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "45%",
            borderRadius: 5,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "10px",
            colors: ["#333"],
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: Object.keys(leads_targets),
          labels: {
            style: {
              fontSize: "17px",
            },
          },
        },
        yaxis: {
          title: {
            text: "Values",
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
        colors: ["#00E396", "#F72C5B"],
      },

      revenueForm: {
        at_profit: null,
        at_revenue: null,
        at_students: null,
        at_leads: null,
        at_year: new Date().getFullYear(),
      },
      hasModalBeenShown: false,
    };
  },
  methods: {
    openAreYouSureModal() {
      if (
        !this.revenueForm.at_revenue ||
        !this.revenueForm.at_profit ||
        !this.revenueForm.at_students ||
        !this.revenueForm.at_leads
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Please fill all the fields",
            icon: "XIcon",
            variant: "danger",
          },
        });
        return;
      }
      this.$bvModal.show("are-you-sure");
    },
    async addAnnualTargets() {
      try {
        const res = await AgentServices.addAnnualTarget(this.revenueForm);
        if (res.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Annual Target Added Successfully",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.getAnnualTargets();
          this.showForm = false;
          this.$bvModal.hide("revenue-modal");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAnnualTargets() {
      try {
        const res = await CommonServices.getAnnualTarget();
        if (res.data.status) {
          // console.log("Annual Targets", res);
          if (res.data.data.annual_target.length == 0) {
            this.showForm = true;
            return;
          }
          const annualTargets = res.data.data.annual_target[0];
          // const visa_students = res.data.data.visa_students;
          const visa_students = 22;
          const revenue =
            res.data.data.total_commission_revenue +
            res.data.data.total_service_commission;
          const expenses = res.data.data.total_expenses;
          const profit = revenue - expenses;

          //target
          const revenue_targets = {
            "Profit ": annualTargets.at_profit,
            "Revenue": annualTargets.at_revenue,
            
          };

          const leads_targets = {
            "Visa Students": annualTargets.at_students,
            Leads: annualTargets.at_leads,
          };

          //revenue_achieved
          const revenue_achieved = {
            "Profit ": profit,
            Revenue: revenue,
          };

          const leads_achieved = {
            "Visa Students": visa_students,
            Leads: this.leads_achieved,
          };

          //revenue_pending
          const revenue_pending = Object.keys(revenue_achieved).map((key) => {
            const total = revenue_targets[key];
            return Math.max(0, total - revenue_achieved[key]);
          });

          const leads_pending = Object.keys(leads_achieved).map((key) => {
            const total = leads_targets[key];
            return Math.max(0, total - leads_achieved[key]);
          });

          this.revenue_series = [
            {
              name: "Achieved",
              data: Object.values(revenue_achieved),
            },
            {
              name: "Pending",
              data: revenue_pending,
            },
          ];

          this.leads_series = [
            {
              name: "Achieved",
              data: Object.values(leads_achieved),
            },
            {
              name: "Pending",
              data: leads_pending,
            },
          ];

          this.revenue_chartOptions = {
            ...this.revenue_chartOptions,

            xaxis: {
              categories: Object.keys(revenue_targets),
              labels: {
                formatter: function (val, idx) {
                  return `${val} : ${revenue_targets[val]}`;
                },
                style: {
                  fontSize: "17px",
                },
              },
            },
          };

          this.leads_chartOptions = {
            ...this.leads_chartOptions,

            xaxis: {
              categories: Object.keys(leads_targets),
              labels: {
                formatter: function (val, idx) {
                  return `${val} : ${leads_targets[val]}`;
                },
                style: {
                  fontSize: "17px",
                },
              },
            },
          };
        }
      } catch (error) {
        console.error("Error in getAnnualTargets ", error);
      }
    },
    async getMyLeads() {
      try {
        const date = `${moment(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        ).format("YYYY-MM-DD")} to ${moment(
          new Date(new Date().getFullYear(), 11, 31)
        ).format("YYYY-MM-DD")}`;
        // console.log("Bar",date);
        const res = await this.lmsApi.getMyLeads({
          registration_date: date,
        });

        if (res.data.status) {
          // console.log("Leads", res);
          // this.leads_achieved = res.data.data.total;
          this.leads_achieved = 52;
        }
      } catch (error) {
        console.error(`Error in getMyLeads `, error);
      }
    },
    async getLMSAccess() {
      try {
        const res = await CommonServices.getLMSAccessToken();
        this.accessToken = res.data.data;

        if (!this.accessToken) {
          this.accessTokenFailed = true;
          return;
        }

        this.lmsApi = LmsServices(this.accessToken);

        await this.getMyLeads();
      } catch (error) {
        console.error(`Error in getLMSAccess `, error);
      }
    },
    refreshBarChart() {
      this.getAnnualTargets();
    },
  },
  async beforeMount() {
    await this.getLMSAccess();
    await this.getAnnualTargets();
    const hasModalBeenShown = localStorage.getItem("hasModalBeenShown");
    this.hasModalBeenShown = hasModalBeenShown;
    if (this.showForm && !hasModalBeenShown) {
      this.$bvModal.show("revenue-modal");
      localStorage.setItem("hasModalBeenShown", "true");
    }
  },
};
</script>
<style scoped>
.form_btn {
  position: absolute;
  top: 20px;
  left: 200px;
  z-index: 100;
}
.form_parent {
  position: relative;
}

</style>
